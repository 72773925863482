import styled from 'styled-components';

export const MainDiv = styled.div`
  margin: 0;
  padding: 0;
  color: #198e48;
  font-family: 'Gotham-Book', arial, sans-serif;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.02em;
`;
export const SubDiv = styled.div`
  width: 1100px;
  margin: 0 auto; 
  padding: 40px 0 310px 0;
  text-align: left;
  @media (max-width: 1100px)
 {
  width: 100%;
    padding: 30px;
}
`;
export const HeaderH1 = styled.h1`
  margin: 0 0 35px 0;
  padding: 0;
  font-family: 'Gotham-Bold', arial, sans-serif;
  font-weight: normal !important;
  color: #198e48;
  font-size: 50px;
  line-height: 60px;
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
  @media (max-width: 1100px)
  {
    font-size: 30px;
    line-height: 40px;
  }
`;
export const HeaderH2 = styled.h2`
  margin: 0 0 35px 0;
  padding: 0;
  font-family: 'Gotham-light', arial, sans-serif !important;
  font-weight: normal !important;
  color: #004e1f !important;
  font-size: 22px;
  line-height: 32px;
  b {
    font-family: 'Gotham-Bold', arial, sans-serif !important;
    font-weight: bold !important;
  }
  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  @media (max-width: 1100px)
 {
    font-size: 18px !important;
    line-height: 30px !important;
}
`;

export const ParaText = styled.p`
  color: #004e1f;
  margin: 0 0 30px 0;
  padding: 0;
  b {
    font-family: 'Gotham-Bold', arial, sans-serif !important;
    font-weight: bold !important;
  }
  a {
    cursor: pointer;
    text-decoration: none;
    text-decoration: none;
    code {
      color: #eea200 !important;
    }
  }
  code {
    font-size: 12px;
    line-height: 18px;
    color: #666;
    font-family: 'Gotham-Book', arial, sans-serif;
  }
  
  i {
    font-size: 14px !important;
    line-height: 130%;
    color: #666;
    font-family: 'Gotham-Book', arial, sans-serif;
    letter-spacing: 0.02em;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 25px;
    }
  }
`;

export const HeaderH3 = styled.h3`
  margin: 0 0 35px 0;
  padding: 0;
  font-family: 'Gotham-Light', arial, sans-serif;
  font-weight: normal !important;
  color: #008168;
  font-size: 26px;
  line-height: 35px;
  letter-spacing: 0.02em;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 20px;
  }
`;


export const ImageStyle = styled.img`
  height: auto !important;
  width: auto !important;
  border: 0;
  vertical-align: top;
  margin-bottom: 30px;
`;

export const SpanStyle = styled.span`
  position: relative;
  top: -105px;
  @media (max-width: 991px) {
    top: -80px;
  }
  @media (max-width: 767px) {
    top: -70px;
  }
`;