import React from 'react';
import * as Styled from './slpCommunitystyle';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useContentfulImages } from '../../hooks/useContentfulImages';
import isExternalUrl, { isExternalUrlhref, isYoutubeLink,addTrailingSlash } from '../../utils';
import { useEffect } from 'react';

const SlpCommunity = ({ sectionData, location }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <Styled.HeaderH1>{children}</Styled.HeaderH1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.HeaderH2>{children}</Styled.HeaderH2>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulImages(node.data.target.sys.id);
        return asset.node.file.url.includes('image') ? (
          <Styled.ImageStyle src={asset.node.file.url} alt={asset.node.title} />
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <>
      <Styled.MainDiv>
        <Styled.SubDiv>
          {sectionData?.elementId?.trim() && (
            <Styled.SpanStyle
              id={sectionData?.elementId?.trim()}
            ></Styled.SpanStyle>
          )}
          {sectionData?.header?.trim() && (
            <Styled.HeaderH1>{sectionData.header}</Styled.HeaderH1>
          )}
          {sectionData?.contentDetails &&
            documentToReactComponents(
              JSON.parse(sectionData?.contentDetails?.raw),
              optionsMainStyle
            )}
        </Styled.SubDiv>
      </Styled.MainDiv>
    </>
  );
};
export default SlpCommunity;
